<template>
  <container class="collectable-detail max-w-screen-md py-16">
    <h1 class="text-3xl font-bold text-center uppercase mb-16">
      edit collectable
    </h1>

    <div class="form-container">
      <form @submit="onSubmit">
        <div class="input-wrapper">
          <label>Select artist</label>
          <AutoComplete v-model="artistSelectionField.value" :suggestions="filteredArtists" @complete="searchArtists($event)" field="name">
            <template #item="slotProps">
              <div><img class="w-8 mr-4 inline" :src="slotProps.item.avatar" alt="avatar">  {{slotProps.item.name}}</div>
            </template>
          </AutoComplete>
          <span>{{ artistSelectionField.errors[0] }}</span>
        </div>

        <div class="input-wrapper">
          <label>purchase type</label>
          <select v-model="purchaseTypeSelectionField.value">
            <option :value="2">SALE</option>
            <option :value="1">AUCTION</option>
          </select>
          <span>{{ purchaseTypeSelectionField.errors[0] }}</span>
        </div>

        <h2 class="text-2xl font-bold text-center uppercase my-16">
          collectable media
        </h2>
        <div class="input-wrapper">
          <label>Upload Zone</label>
          <ordered-upload v-model="mediaField.value" multiple allowOrdering/>
          <span>{{ mediaField.errors[0] }}</span>
         </div>

        <h2 class="text-2xl font-bold text-center uppercase my-16">
          collectable info
        </h2>

        <div class="input-wrapper">
          <label>title</label>
          <input
            v-model="titleField.value"
            type="text"
            placeholder="Title"
          />
          <span>{{ titleField.errors[0] }}</span>
        </div>

        <div class="input-wrapper">
          <label>slug</label>
          <input
            v-model="slugField.value"
            type="text"
            placeholder="slug"
            disabled
          />
          <span>{{ slugField.errors[0] }}</span>
        </div>

        <div class="input-wrapper">
          <label>medium</label>
          <input
            v-model="mediumField.value"
            type="text"
            placeholder="medium"
          />
          <span>{{ mediumField.errors[0] }}</span>
        </div>


        <div class="input-wrapper">
          <label>type</label>
          <select v-model="typeSelectionField.value">
            <option value="nft">NFT</option>
            <option value="tangible_nft">NFT + TANGIBLE</option>
            <option value="tangible">TANGIBLE</option>
          </select>
          <span>{{ typeSelectionField.errors[0] }}</span>
        </div>

        <div class="input-wrapper">
          <label>category</label>
          <select v-model="categoryField.value">
            <option value="regular">Regular</option>
          </select>
          <span>{{ categoryField.errors[0] }}</span>
        </div>

        <div class="input-wrapper">
          <label>version</label>
          <select v-model="versionSelectionField.value">
            <option :value="1">v1.0</option>
            <option :value="2">v2.0</option>
          </select>
          <span>{{ versionSelectionField.errors[0] }}</span>
        </div>

        <div class="input-wrapper">
          <label>Contract Address</label>
          <input
            v-model="contractAddressField.value"
            type="text"
            placeholder="contract address"
          />
          <span>{{ contractAddressField.errors[0] }}</span>
        </div>

        <div class="input-wrapper">
          <label>description</label>
          <Editor v-model="descriptionField.value">
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-underline"></button>
              </span>
            </template>
          </Editor>
          <span>{{ descriptionField.errors[0] }}</span>
        </div>

        <div class="input-wrapper" v-if="isAuction">
          <label>edition</label>
          <input
            v-model="editionField.value"
            type="number"
            placeholder="edition"
          />
          <span>{{ editionField.errors[0] }}</span>
        </div>

        <div class="input-wrapper">
          <label>edition of</label>
          <input
            v-model="editionOfField.value"
            type="number"
            placeholder="Edition Of"
          />
          <span>{{ editionOfField.errors[0] }}</span>
        </div>

        <div class="input-wrapper">
          <label>Is Reserve Price Auction</label>
          <input
            v-model="isReservePriceAuctionField.value"
            type="checkbox"
            placeholder="Is Reserve Price Auction"
          />
          <span>{{ isReservePriceAuctionField.errors[0] }}</span>
        </div>

        <div class="input-wrapper">
          <label>Is Open Edition</label>
          <input
            v-model="isOpenEditionField.value"
            type="checkbox"
            placeholder="Is Open Edition"
          />
          <span>{{ isOpenEditionField.errors[0] }}</span>
        </div>

        <div class="input-wrapper">
          <label>Auto Generate Claim Page</label>
          <input
            v-model="autoGenerateClaimPageField.value"
            type="checkbox"
            placeholder="Auto Generate Claim Page"
          />
          <span>{{ autoGenerateClaimPageField.errors[0] }}</span>
        </div>

        <div class="input-wrapper">
          <label>is active</label>
          <input
            v-model="isActiveField.value"
            type="checkbox"
            placeholder="isActive"
          />
          <span>{{ isActiveField.errors[0] }}</span>
        </div>

        <div class="input-wrapper">
          <label>is sold out</label>
          <input
            v-model="isSoldOutField.value"
            type="checkbox"
            placeholder="isSoldOut"
          />
          <span>{{ isSoldOutField.errors[0] }}</span>
        </div>

        <div class="input-wrapper">
          <label>Is Coming Soon</label>
          <input
            v-model="isComingSoonField.value"
            type="checkbox"
            placeholder="Is Coming Soon"
          />
          <span>{{ isComingSoonField.errors[0] }}</span>
        </div>

        <div class="input-wrapper">
          <label>Is Closed</label>
          <input
            v-model="isClosedField.value"
            type="checkbox"
            placeholder="Is Closed"
          />
          <span>{{ isClosedField.errors[0] }}</span>
        </div>

        <div class="input-wrapper">
          <label>Is Hidden From Drop List</label>
          <input
            v-model="isHiddenFromDropList.value"
            type="checkbox"
            placeholder="Is Hidden From Drop List"
          />
          <span>{{ isHiddenFromDropList.errors[0] }}</span>
        </div>
        <!-- DATEPCIKER x2 -->

        <div class="input-wrapper">
          <label>STARTS AT - YOUR LOCAL TIME</label>
          <Calendar
            v-model="startsAtField.value"
            :showTime="true"
            dateFormat="dd/mm/yy"
            timeFormat="hh:mm"
            placeholder="Starts At date"
          />
          <span>{{ startsAtField.errors[0] }}</span>
        </div>

        <div class="input-wrapper" v-if="isAuction || isOpenEditionField.value">
          <label>ENDS AT - YOUR LOCAL TIME</label>
          <Calendar
            v-model="endsAtField.value"
            :showTime="true"
            :disabled="!isAuction && !isOpenEditionField.value"
            dateFormat="dd/mm/yy"
            timeFormat="hh:mm"
            placeholder="Ends At"
          />
          <span>{{ endsAtField.errors[0] }}</span>
        </div>

        <!-- DATEPCIKER x2 -->

        <div class="input-wrapper" v-if="isAuction">
          <label>min bid</label>
          <input
            v-model="minBidField.value"
            type="text"
            placeholder="minBid"
            :disabled="!isAuction"
          />
          <span>{{ minBidField.errors[0] }}</span>
        </div>

        <div class="input-wrapper" v-if="!isAuction">
          <label>price</label>
          <input
            v-model="priceField.value"
            type="text"
            placeholder="price"
            :disabled="isAuction"
          />
          <span>{{ priceField.errors[0] }}</span>
        </div>

        <div class="input-wrapper" v-if="!isAuction">
          <label>available quantity</label>
          <input
            v-model="availableQuantityField.value"
            type="text"
            placeholder="Available Quantity"
            :disabled="isAuction"
          />
          <span>{{ availableQuantityField.errors[0] }}</span>
        </div>

        <div class="input-wrapper">
          <label>artist statement</label>
          <Editor v-model="artistStatementField.value">
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-underline"></button>
              </span>
            </template>
          </Editor>
          <span>{{ artistStatementField.errors[0] }}</span>
        </div>

<!--        <div class="input-wrapper">-->
<!--          <label>shipping location</label>-->
<!--          <input-->
<!--            v-model="shippingLocationField.value"-->
<!--            type="text"-->
<!--            placeholder="shippingLocation"-->
<!--          />-->
<!--          <span>{{ shippingLocationField.errors[0] }}</span>-->
<!--        </div>-->

        <div class="input-wrapper">
          <label>Nft contract address (0x13bab10a88fc5f6c77b87878d71c9f1707d2688a)</label>
          <input
            v-model="nftContractAddressField.value"
            type="text"
            placeholder="Nft contract address"
          />
          <span>{{ nftContractAddressField.errors[0] }}</span>
        </div>

<!--        <div class="input-wrapper">-->
<!--          <label>nft ipfs hash</label>-->
<!--          <input-->
<!--            v-model="nftIpfsHashField.value"-->
<!--            type="text"-->
<!--            placeholder="Artists full nftIpfsHash"-->
<!--          />-->
<!--          <span>{{ nftIpfsHashField.errors[0] }}</span>-->
<!--        </div>-->

        <div class="input-wrapper">
          <label>nft token id</label>
          <input
            v-model="nftTokenIdField.value"
            type="text"
            placeholder="nft Token Id"
          />
          <span>{{ nftTokenIdField.errors[0] }}</span>
        </div>

        <button type="submit" class="button primary">Submit</button>
      </form>
    </div>
  </container>
</template>


<script>
import Container from "@/components/Container.vue";
import Calendar from "primevue/calendar";
import Editor from "primevue/editor";
import OrderedUpload from "@/components/OrderedUpload/OrderedUpload.vue";

import useEditCollectableInformation from "@/hooks/useEditCollectableInformation.js";

export default {
  name: "CollectableDetail",
  components: { Container, Calendar, Editor, OrderedUpload },
  async setup() {
    const {
      // Form Related
      artists,
      versions,
      mediaEditable,
      artistSelectionField,
      titleField,
      slugField,
      mediumField,
      typeSelectionField,
      purchaseTypeSelectionField,
      categoryField,
      contractAddressField,
      mediaField,
      versionSelectionField,
      descriptionField,
      editionField,
      editionOfField,
      isActiveField,
      isSoldOutField,
      startsAtField,
      priceField,
      artistStatementField,
      isComingSoonField,
      isClosedField,
      isHiddenFromDropList,
      isReservePriceAuctionField,
      isOpenEditionField,
      autoGenerateClaimPageField,
      // shippingLocationField,
      nftContractAddressField,
      // nftIpfsHashField,
      nftTokenIdField,
      availableQuantityField,
      endsAtField,
      minBidField,
      isAuction,
      onSubmit,
      loadCollectable,
      searchArtists,
      filteredArtists,
      isNew,
    } = useEditCollectableInformation();

    if (!isNew) {
      await loadCollectable();
    }

    return {
      onSubmit,

      artists,
      artistSelectionField,
      mediaEditable,
      versions,
      isAuction,

      titleField,
      slugField,
      mediumField,
      typeSelectionField,
      purchaseTypeSelectionField,
      categoryField,
      contractAddressField,
      descriptionField,
      editionField,
      editionOfField,
      isActiveField,
      isSoldOutField,
      startsAtField,
      priceField,
      artistStatementField,
      versionSelectionField,
      isComingSoonField,
      isHiddenFromDropList,
      isReservePriceAuctionField,
      autoGenerateClaimPageField,
      isOpenEditionField,
      // shippingLocationField,
      nftContractAddressField,
      // nftIpfsHashField,
      nftTokenIdField,
      availableQuantityField,
      endsAtField,
      minBidField,
      mediaField,

      // Form Related
      searchArtists,
      filteredArtists,
      isClosedField,
    };
  },
};
</script>

<style scoped>
</style>
